.c-search-results {
  padding-bottom: 10rem;
  &__count {
    margin-bottom: 3rem;
    small {

    }
  }
  &__single {
    margin-bottom: 5rem;
    &__header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $clr-secondary;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      h2 {
        margin: 0 1rem 0 0;
      }
    }

    small {
      font-size: 1.6rem;
      margin-bottom: .5rem;
      display: block;
      a {
        font-size: 1.6rem;
        text-decoration: underline;
      }
    }

    &__row {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $clr-secondary;
      background-color: $clr-secondary;
      padding: .5rem 1.5rem .5rem 1rem;
      margin-bottom: 1rem;
      border-radius: .5rem;

      &:hover {
        border-bottom: 1px solid $color-black;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &__action {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 1rem 0 0;

        a, button {
          margin: .5rem 1rem .5rem 0;
        }

      }

      span {
        margin-right: 3rem;
        white-space: nowrap;
        font-size: 1.7rem;

        @media screen and (max-width: 650px) {
          margin-right: 1.5rem;
        }

        span {
          text-decoration: underline;
          font-weight: 600;
          white-space: nowrap;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
