.c-header {
  border-bottom: 1px solid $clr-secondary;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 588px) {
    height: 110px;
    max-height: 110px;
    padding: 1rem 1rem
  }

  @media screen and (max-width: 1300px) {
    height: 60px;
    max-height: 60px;
  }

  @media screen and (max-width: 588px) {
    height: 80px;
    max-height: 80px;
  }

  &__container {
    display: flex;

    @media screen and (max-width: 1300px) {
      flex-wrap: wrap;
    }

    &__logo {
      img {
        margin-top: 1rem;
        max-width: 20rem;
        max-height: 5rem;

        @media screen and (max-width: 1300px) {
          margin-top: 1rem;
          max-width: 18rem;
          margin-right: 1rem;
        }

        @media screen and (max-width: 588px) {
          margin-top: 1.5rem;
          margin-right: 1rem;
          max-width: 13rem;
          max-height: 4rem;
        }

      }
    }

    &__location-title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 1rem;
      margin-right: 1rem;

      span {
        font-size: 1.5rem;
        font-weight: bold;
      }

      &__select{
        width: 350px;
      }

      @media screen and (max-width: 588px) {
        margin-left: 0;

        &__select {
          margin-top: 1rem;
          margin-bottom: 2rem;
          width: 230px;
        }
      }

    }
  }

  &__nav {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1300px) {
      display: none;
    }

    li {
      margin: 0 1rem;

      a {
        font-size: 1.7rem;
        font-weight: 600;
      }
    }
  }

  &__misc {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1300px) {
      display: none;
    }

    button {
      margin-left: .5rem;

      i {
        font-size: 2rem;
      }
    }

    .btn--outline {
      span, i {
        color: $color-black;
      }
    }

    .btn--square {
      span, i, i:before {
      }
    }
  }

  &__menu-trigger {
    width: 35px;
    height: 40px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1300px) {
      display: flex;
    }

    span {
      width: 100%;
      height: 4px;
      background-color: $clr-primary;

      &:nth-of-type(2) {
        margin: 4px 0;
      }
    }
  }

}
