.c-scan {
  text-align: center;

  &__qr {
    padding: 10rem 0;

    h2 {
      margin-top: 2rem;
    }

    img {
      max-width: 10rem;
      width: 10rem;
    }

    &--scanning {
      position: relative;
      padding: 0;
      margin-top: 10rem;

      img {
        position: absolute;
        left: calc(50% - 4rem);
        top: 6rem;
        max-width: 8rem;
        width: 8rem;
      }
    }

    &--load {
      width: 20rem;
      height: 20rem;
      margin: 0 auto;
      border: solid 5px #022d6b;
      border-radius: 50%;
      border-right-color: transparent;
      border-bottom-color: transparent;
      -webkit-transition: all 0.5s ease-in;
      -webkit-animation-name: rotate;
      -webkit-animation-duration: 1.0s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      transition: all 0.5s ease-in;
      animation-name: rotate;
      animation-duration: 1.0s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

    }

    &--success, &--failed {
      img {
        max-width: 12rem;
        width: 12rem;
      }
    }

    &--small {
      padding: 6rem 0;
    }
  }

  &__manual {
    padding-bottom: 8rem;
  }

  &__on-demand {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .btn {
      background-color: #022d6b;
      color: #fff;
      font-weight: 600;
      font-size: 2rem;
      padding: 0 5rem;
      height: 6rem;
    }

    .back-btn {
      margin-top: 5rem;
    }

    &--amount {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 16rem;
      margin: 2rem 0 4rem 0;
      @media screen and (max-width: 460px) {
        max-width: 13rem;
      }

      form {
        padding: 0;
        margin: 0;
        background-color: transparent;

        input {
          border: 0 !important;
          font-size: 4rem !important;
        }
      }
    }

    .plus, .minus {
      font-size: 4rem !important;
      width: 6rem;
      height: 6rem;
      border-radius: 6rem;
      background-color: #022d6b;
      color: #fff;
      display: flex;
      justify-content: center;
    }

    .plus {
      margin-bottom: 1rem;
    }
  }

  small {
    font-size: 1.4rem;
    font-weight: 600;
  }

  &__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: .5;
    padding: 0 2rem 2rem 2rem;
    width: 100%;

    small {
      font-size: 1.3rem;
    }
  }

  &__loading-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 30%;
    height: .8rem;
    background-color: #022d6b;

  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.back-btn {
  display: block;
  margin-top: 5rem;

  &:hover {
    cursor: pointer;
  }
}

.c-scan-text{
  h2{
    margin-bottom: 0;
  }
}
