.c-subject-header {
  padding: 2.5rem 0 2rem;
  border-bottom: 1px solid #DEE5F0;
  margin-bottom: 3rem;

  @media screen and (max-width: 650px) {
    padding: 1.5rem 0;
    margin-bottom: 2rem;
  }

  h1, h2 {
    margin: 0;
  }

  &--split {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;

      a, button {
        margin-top: 1rem;
      }
    }
  }

  &--actions {
    margin-bottom: 2.5rem;
  }

  &__buttons {
    display: flex;

    a, button {
      margin-left: 1rem;
    }

    @media screen and (max-width: 850px) {
      flex-wrap: wrap;

      a, button {
        margin-left: 0;
        margin-right: .5rem;
        margin-top: .5rem;
      }
    }
  }

  &__split {
    display: flex;
    align-items: center;

    @media screen and (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 650px) {
      width: 100%;
    }

    .c-form__select {
      margin-left: 3rem;

      @media screen and (max-width: 850px) {
        margin-left: 0;
      }

      @media screen and (max-width: 650px) {
        width: 100%;
      }


    }
  }

  &__check {
    display: flex;

    @media screen and (max-width: 850px) {
      margin: 1rem 0;
    }

    small {
      display: none;

      @media screen and (max-width: 850px) {
        display: flex;
        font-size: 1.7rem;
        margin-right: 2rem;
      }

    }

    label {
      display: flex;
      align-items: center;
      margin-left: 1.8rem;

      @media screen and (max-width: 850px) {
        margin-left: 0;
        margin-right: 1.5rem;
      }

      span {
        font-weight: 600;
        margin-left: 1rem;
        font-size: 1.7rem;
      }
    }
  }

  &--closed {
    padding: 1rem 1rem 1rem 2rem;
    background-color: $clr-secondary;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    margin-bottom: 0;

    h2 {
      font-size: 2rem;
    }
  }

  &--tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 2rem;
    }
  }

  &__tabs {
    display: flex;

    @media screen and (max-width: 1000px) {
      margin-bottom: 1rem;
      flex-wrap: wrap;
    }

    a {
      margin-left: 2rem;
      padding: 2.75rem 0 2.25rem;
      border-bottom: 3px solid transparent;
      &:hover {
        border-bottom: 3px solid $clr-secondary;
      }

      @media screen and (max-width: 1000px) {
        padding: .5rem .5rem .5rem 0;
        margin: 0 2rem 0 0;
        border-bottom: none;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid transparent;
        }
      }
    }

    .is-active {
      font-weight: 600;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $clr-primary;
      }
      @media screen and (max-width: 1000px) {
        border: 1px solid $clr-primary;
        padding: .5rem 1rem;
        border-radius: .5rem;

        &:before {
          display: none;
        }
      }
    }
  }
}
