.c-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: $gray-new;
  margin-top: 10rem;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: center;

    span {
      margin: 0.5rem 0.5rem 1rem;
      text-align: center;
    }

    a {
      text-align: center;
    }
  }

  span, a {
    color: $color-black;
    margin: 0 .5rem;
    font-size: 1.6rem;
  }

  span {
    font-weight: 600;
  }

  div {
    display: flex;
  }

  a {
    text-decoration: underline;
  }
}
