.s-dashboard-blocks {
  margin-bottom: 3rem;
}

.s-dashboard-table {
  margin-bottom: 3rem;
}

.s-dashboard-search {
  display: flex;
  border-radius: .5rem;

  input {
    width: 100%;
  }
}

.s-dashboard-search-table {
  margin-bottom: 3rem;
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  .c-table {
    width: 60%;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  .c-icon-blocks {
    width: 40%;
    flex-wrap: wrap;
    margin-right: 2rem;

    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-right: 0;
      flex-wrap: initial;
    }
    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
    }
  }

  .c-icon-blocks__single {
    width: calc(50% - .5rem);
    margin-right: 1rem;
    margin-bottom: 1rem;

    &:nth-of-type(even) {
      margin-right: 0;
    }

    @media screen and (max-width: 1000px) {
      width: calc(25% - .5rem);

      &:nth-of-type(even) {
        margin-right: 1rem;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 650px) {
      width: calc(50% - .5rem);

      &:nth-of-type(even) {
        margin-right: 0;
      }

      &:last-of-type {
        margin-right: initial;
      }
    }
  }
}

.s-dashboard-forecast {
  margin-bottom: 3rem;
}

.s-search {
  position: relative;
  margin-bottom: 1rem;
  input {
    width: 100%;
    height: 4.5rem;
    padding: .25rem 12rem .25rem 2rem;
    font-size: 1.6rem;
    border-radius: .5rem;
    border: 1px solid $color-black;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid $color-black;

    span, i:before {
      color: $color-black !important;
    }
  }
}

.s-order-detail {
  padding-bottom: 10rem;

  &--finish {
    .c-info-blocks__flex {

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      a, button {
        height: 4.5rem;
        min-height: 4.5rem;
        max-height: 4.5rem;

        @media screen and (max-width: 500px) {
          margin-bottom: 1rem;
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
  }
}

.s-settings {
  padding-bottom: 10rem;
}

.s-order-actions {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media screen and (max-width: 850px) {
    justify-content: flex-start;
  }

  a, button {
    margin: .25rem;
    margin-right: .5rem !important;

    @media screen and (max-width: 850px) {
      margin-left: 0;
      margin-right: 1rem;
    }
  }
}

.s-rental-overview {
  .c-Rental-overview {
    padding: 0;
  }
}
