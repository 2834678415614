.c-search-box {
  background-color: $clr-secondary;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;

  input {
    background-color: transparent;
    border: none;
    width: 100%;
    border-radius: none;
    font-size: 1.7rem;

    &:focus {
      outline: none;
    }
  }
  div {
    display: flex;

    button {
      margin-left: 1rem;
      background-color: $color-black;

      i:before {
        color: $color-white !important;
      }
    }
  }
}
