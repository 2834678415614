.o-container {
	width: 100%;
	margin: 0 auto;

	&--primary {
		max-width: 1240px;

        @media (max-width: 1340px) {
          max-width: calc(100% - 100px);
        }
        @media (max-width: 800px) {
          max-width: calc(100% - 60px);
        }
        @media (max-width: 650px) {
          max-width: calc(100% - 40px);
        }
        @media (max-width: 400px) {
          max-width: calc(100% - 30px);
        }
	}

	&--secondary {
		max-width: 1275px;

		@media (max-width: 1375px) {
			max-width: calc(100% - 100px);
		}
		@media (max-width: 800px) {
			max-width: calc(100% - 60px);
		}
		@media (max-width: 500px) {
			max-width: calc(100% - 50px);
		}
		@media (max-width: 350px) {
			max-width: calc(100% - 40px);
		}
	}
}
