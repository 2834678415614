.c-info-blocks {
  &__row {
    margin-bottom: 2rem;

    @media screen and (max-width: 850px) {
      margin-bottom: 0;
    }

    &--half {
      display: flex;

      @media screen and (max-width: 850px) {
        flex-wrap: wrap;
      }

      .c-info-blocks__single {
        width: 50%;

        @media screen and (max-width: 850px) {
          width: 100%;
        }

        &:first-of-type {
          margin-right: 2rem;

          @media screen and (max-width: 850px) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__text {
    &__highlight {
      background-color: $clr-primary;
      padding: 2rem;
      margin-bottom: 1em;
      border-radius: .5rem;

      h1, h2, h3, h4, h5, h6 {
        margin: 0;
      }

      h1, h2, h3, h4, h5, h6, ul, ul li, ol, olli, a, span, p, u, strong, i, i:before {
        color: $clr-secondary;
      }

      &--positive {
        background-color: $green-primary;
      }

      &--negative {
        background-color: $red-primary;
      }

      &--orange {
        background-color: $orange-primary;
      }

      &--icon {
        display: flex;
        align-items: center;
        padding: 1.5rem 2rem;

        svg {
          width: 40px;
          height: 30px;
          transform: scale(1.3);
        }

        i {
          font-size: 2rem;
          width: 40px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &__single {

    .c-info-blocks__row:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (max-width: 850px) {
      margin-bottom: 2rem;
    }

    &__body {
      padding: 2rem;
      border-left: 1px solid $clr-secondary;
      border-right: 1px solid $clr-secondary;
      border-bottom: 1px solid $clr-secondary;
      border-bottom-left-radius: .5rem;
      border-bottom-right-radius: .5rem;
      background-color: lighten($clr-secondary, 8%);

      &--scroll {
        overflow-x: auto;
      }

      .c-form__row {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__row {
        display: flex;

        @media screen and (max-width: 500px) {
          flex-direction: column;
          margin-bottom: 1rem;
        }

        img {
          max-width: 15rem;
          max-height: 10rem;
        }

        span {
          width: 50%;
          word-break: break-word;

          &:first-of-type {
            padding-right: 1rem;
            font-weight: 700;
          }

          @media screen and (max-width: 500px) {
            width: 100%;
          }
        }

        &--defects {
          margin: 1rem 0;

          .blocks {
            width: 50%;
            display: flex;
            flex-wrap: wrap;

            span {
              font-weight: 700 !important;
              font-size: 1.3rem;
              background-color: rgb(2, 45, 107);
              padding: 0.5rem 1.25rem !important;
              color: #fff;
              border-radius: 0.5rem;
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;
              width: auto;

              &:last-of-type {
                margin-right: 0;
                font-weight: 700;
              }
            }
          }
        }
      }

      &--half {
        display: flex;
        flex-wrap: wrap;

        .c-info-blocks__single__body__row {
          width: 50%;

          @media screen and (max-width: 850px) {
            width: 100%;
          }

          span {
            padding-right: 1rem;
          }
        }
      }
    }
  }

  &__category-link{
    font-size: 1rem !important;
    text-decoration: underline;
    color: #000;
  }

}
