.c-subject-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 5rem;

  @media screen and (max-width: 850px) {
    margin-top: 2.5rem;
  }

  &__single {
    display: flex;
    align-items: center;
    border-right: 2px solid $color-white;
    padding: 2rem 2.5rem;
    flex-grow: 1;
    white-space: nowrap;

    @media screen and (max-width: 1023px) {
      justify-content: center;
    }

    @media screen and (max-width: 850px) {
      padding: 1.25rem 1.5rem;
    }

    &:last-of-type {
      border-right: none;
    }

    &__number {
      margin-right: 1rem;
      @include square-dimensions(2.6rem);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      small {
        font-weight: 700;
        font-size: 1.6rem;
      }
    }
    h2 {
      font-size: 2rem;
      margin: 0;

      @media screen and (max-width: 1023px) {
        font-size: 1.7rem;
      }

      @media screen and (max-width: 850px) {
        display: none;
      }
    }
  }
}
