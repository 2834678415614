.c-table-standard {
  width: 100%;
  min-width: 75rem;
  td {
    font-size: 1.6rem;
  }
  tr:first-of-type {

    td {
      font-weight: 600;
    }
  }
  tr {
    border-bottom: 1px solid $gray-new;
  }

  &--prices {
    margin-bottom: 1rem;
    tr {
      td:last-of-type {
        text-align: right;
      }
    }
  }

  &--datepicker_availability {

    &--start {
      margin-right: 1.5rem;
      font-size: 1.4rem;
      position: relative;
      top: .2rem;
      @media screen and (max-width: 849px) {
        position: relative;
        top: .5rem;
      }
    }

    &--till{
      margin-right: 1.5rem;
      font-size: 1.4rem;
      @media screen and (max-width: 849px) {
        position: relative;
        top: .5rem;
      }
    }
    .rs-picker-toggle-caret {
      top: 5px !important;
    }
  }
}

.c-table-filter {
  margin-top: 2rem;
  ul {
    padding: 0;
    padding-left: 0 !important;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;

    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }

    li {
      padding: 1rem;
      background-color: $gray-new;
      margin-right: 2px !important;
      font-size: 1.4rem;
      font-weight: 600;
      cursor: pointer;

      @media screen and (max-width: 1050px) {
        margin-bottom: 2px;
        border-radius: .5rem;
      }

      &:hover {
        background-color: darken($gray-new, 5%);
      }
      &:first-of-type {
        border-top-left-radius: .5rem;

      }
      &:last-of-type {
        border-top-right-radius: .5rem;
      }
    }
    .is-active {
      background-color: $clr-primary;
      color: $clr-secondary;
      pointer-events: none;
    }
  }
}

.c-table--dashboard {
  overflow-x: unset !important;
  min-width: 95rem !important;
}

