.c-loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  &:before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  &.is-loading {

    pointer-events: all;

    &:before {
      opacity: 1;
    }

    .box {
      opacity: 1;
      position: relative;
      margin: auto;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        opacity: 1;
        max-width: 25rem;
        max-height: 25rem;
      }
      svg {
        width: 100px;
        height: 100px;
        margin: 20px;
        display:inline-block;
      }
    }

  }

  .box {
    position: relative;
    margin: auto;
    border: none;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-bottom: 2rem;
      max-width: 20rem;
      max-height: 20rem;
    }
  }
}

.c-loading-scan {
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 400px) {
    width: 4rem;
    height: 4rem;
  }

  &__qr {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 2.8rem;

    @media screen and (max-width: 400px) {
      width: 2.5rem;
    }
  }
  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 5rem;
    height: 5rem;
    transform: scale(2.2);
    display: none;

    @media screen and (max-width: 400px) {
      width: 4rem;
      height: 4rem;
    }
  }
}