.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &__close {
    @include square-dimensions(3.3rem);
    border-radius: .7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: absolute;
    top: 2rem;
    right: 2rem;

    i {
      font-size: 1.7rem;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__action {
    display: flex;
    margin-top: 2rem;

    a, button {
      margin-right: 1rem;
    }
  }


  &__content {
    background-color: $color-white;
    border-radius: 1rem;
    position: relative;
    width: 95rem;
    border: none;
    overflow: hidden;

    @media screen and (max-width: 950px) {
      width: calc(100% - 8rem);
    }

    @media screen and (max-width: 550px) {
      //width: calc(100% - 6rem);
    }

    h2 {
      font-size: 3rem;
      display: flex;
      margin-bottom: 1rem;

      @media screen and (max-width: 700px) {
        font-size: 2.5rem;
      }

      @media screen and (max-width: 500px) {
        font-size: 2rem;
      }

      i {
        line-height: 1;
        display: flex;
        align-items: center;
        margin-right: 1rem;
      }
    }

    p {
      font-size: 1.6rem;
      line-height: 1.3;
    }

    &--text {
      padding: 4rem 8rem 5rem 5rem;

      @media screen and (max-width: 600px) {
        padding: 3.5rem 5.5rem 3.5rem 3.5rem;
      }
    }

    &--service{
      overflow: visible;
    }

    &--locations {
      display: flex;

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }
    }

    &--product {
      display: flex;
      padding: 4rem 8rem 5rem 5rem;

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }

      @media screen and (max-width: 600px) {
        padding: 3.5rem;
      }

    }
  }

  &__map {
    width: 45%;

    @media screen and (max-width: 700px) {
      width: 100%;
      min-height: 30rem;
    }
  }

  &__locations {
    padding: 5rem;
    width: 55%;

    @media screen and (max-width: 700px) {
      width: 100%;
    }

    @media screen and (max-width: 600px) {
      padding: 3.5rem;
    }

    &__container {
      margin-top: 2rem;
    }

    &__single {
      display: flex;
      padding: 1.5rem 0;
      &__number {
        @include square-dimensions(2.7rem);
        border-radius: .6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-right: 1.5rem;

        span {
          font-size: 1.8rem;
          font-weight: 700;
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        span {
          line-height: 1;
          font-size: 1.5rem;
          strong {
            font-weight: 700;
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  &__product {
    &__media {
      width: 50%;
      margin-right: 5rem;

      @media screen and (max-width: 700px) {
        width: 100%;
        margin-right: 0;
      }

      img {
        max-height: 30rem;

        @media screen and (max-width: 700px) {
          max-height: 15rem;
        }
      }

      &__text {
        margin-top: 1.5rem;
        p {
          margin: 0 0 .5rem;
        }
        span, p {
          font-size: 1.6rem;
          line-height: 1.3;
          display: block;
        }
      }
    }
    &__info {
      width: 50%;
      padding-top: 1.5rem;

      @media screen and (max-width: 700px) {
        width: 100%;
        padding-top: 2rem;
      }

      &__amount {
        display: flex;
        align-items: center;
        margin: 2rem 0;

        .c-products__single__amount {
          margin-right: 2.5rem;
          margin-bottom: 0;

          span {
            margin: 0 1.5rem;
          }
        }
      }

      .c-products__single__locations {
        border-radius: 1rem;

        &__single {

          &__name {
            span {
              font-size: 1.6rem;
            }
            span {
              font-size: 1.5rem;
            }
          }

          &:first-of-type {
            border-top: none;
          }
        }
      }
    }
  }

}
