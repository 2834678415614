.c-notification {
  display: flex;
  align-items: center;
  background-color: $clr-primary;
  padding: 1rem 1rem 1rem 1.5rem;
  margin: .5rem 0;
  border-radius: .5rem;
  position: relative;

  span {
    color: $color-white;
    font-size: 1.6rem;
    padding-right: 1.5rem;
    line-height: 1.3;
  }
  i {
    font-size: 2rem;
    margin-right: 1rem;
    &:before {
      color: $color-white;
    }
  }
  &--error {
    background-color: $red-primary;
  }
  &--success {
    background-color: $green-primary;
  }
}
