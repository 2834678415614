.c-date-picker {
  &__button {
    display: inline-flex;
  }
  &__info {
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    h5 {
      font-size: 2rem;
      margin: 0 0 .5rem;
    }
    div {
      display: flex;
      align-items: center;

      .btn--pill {
        padding: .5rem 1.5rem;
      }

      small {
        margin: 0 .5rem !important;
      }

      p, small {
        margin: 0;
        font-size: 1.6rem;
      }
    }
  }
}
