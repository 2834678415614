.c-menu {
  height: 100%;
  min-height: calc(100vh - 60px);
  background-color: $color-white;
  width: 100%;
  z-index: 2;
  .o-container {
    margin-top: 5rem;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem !important;

    li {
      margin: .5rem 0;
    }

    a {
      font-size: 2.5rem;
      font-weight: 600;
    }
  }
  &__misc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    a, button {
      margin-bottom: 1rem;

      i {
        font-size: 2rem;
      }
    }
    .btn--outline {
      span, i {
        color: $color-black !important;
      }
    }
    .btn--square {
      span, i, i:before {
      }
    }
  }
}
