.u-space {
  padding: 4rem 0;

  @media (max-width: 900px) {
    padding: 2rem 0;
  }

  &--middle {
    padding: 0 5rem;

    @media (max-width: 600px) {
      padding: 0 3rem;
    }
  }
  &--large {
    padding: 8rem 0;

    @media (max-width: 600px) {
      padding: 4rem 0;
    }
  }

  &--small {
    padding: 2rem 0;
  }
}