/*------------------------------------*\
  #TYPOGHRAPY
\*------------------------------------*/

body {
  font-weight: 400;
  line-height: 1.6;
  font-family: 'Poppins', serif;
  color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6,
th {
  margin: 0 0 0.6em 0;
  font-weight: 600;
  line-height: 1.18;
}

h1,
.h1 {
  font-size: 3rem;

  @media (max-width: 750px) {
    font-size: 2.7rem;
  }

  @media (max-width: 500px) {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: 2.5rem;

  @media (max-width: 750px) {
    font-size: 2.3rem;
  }

  @media (max-width: 500px) {
    font-size: 2.2rem;
  }
}

h3,
.h3 {
  font-size: 2.2rem;

  @media (max-width: 750px) {
    font-size: 2rem;
  }

  @media (max-width: 500px) {
    font-size: 1.8rem;
  }
}

h4,
.h4 {
  font-size: 2rem;

  @media (max-width: 750px) {
    font-size: 1.8rem;
  }

  @media (max-width: 500px) {
    font-size: 1.6rem;
  }
}

h5,
.h5 {
  font-size: 1.8rem;

  @media (max-width: 750px) {
    font-size: 1.6rem;
  }

  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
}

h6,
.h6 {
  font-size: 1.7rem;

  @media (max-width: 750px) {
    font-size: 1.5rem;
  }
}

p,
.p {
  margin: 0 0 1em 0;
  font-size: 1.8rem;
  color: $color-black;

  &:last-child {
    margin-bottom: 0;
  }
}

span {
  font-size: 1.8rem;
}

a {
  font-size: 1.8rem;
  color: $color-black;
  text-decoration: none;
}

small {
  font-size: 1.5rem;
}
