
.c-two-factor-auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &__body {
    background: #fff;
    padding: 3rem;
    border-radius: 2rem;
  }

  &__header {
    h2{
      margin: 0;
      font-size: 2rem;
    }
  }

  &__content {
    p{
      font-size: 1.6rem;
      margin: 0;
    }
  }

  &__input {
    input{
        width: 100%;
        padding: 1rem;
        margin: 1rem 0;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
    }
  }

  &__button {
    color: white;
    display: flex;

    .tiny-loader{
      transform: scale(0.5);
      width: auto;
    }
  }

  &__error {
    color: red;
    font-size: 1.6rem;
    margin: 1rem 0;
  }
}