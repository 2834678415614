/*------------------------------------*\
  #STANDARD BUTTON
\*------------------------------------*/

.btn {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid transparent;
  height: 4.5rem;
  border-radius: .5rem;
  appearance: none;


  span {
    font-weight: 600;
    font-size: 1.5rem;

    small {
      font-size: 1.3rem;
    }
  }

  &--small {
    padding: .5rem 1.25rem;
    height: 4rem;

    span {
      font-weight: 600;
      font-size: 1.4rem;
    }
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 1.6rem;
      margin-right: 1rem;
    }
  }

  &--rounded {
    border-radius: 1rem;
  }

  &--outline {
    border: 1px solid $clr-secondary;
    background: transparent;

    i, span {
    }
    i:before {
    }
  }

  &--square {
    padding: 0;
    width: 4.5rem;

    i {
      font-size: 1.6rem;
    }
  }

  &--pill {
    border-radius: 100px;
    padding: .5rem 1rem;
    span {
      font-size: 1.3rem !important;
      line-height: 1;
    }
  }

  &--primary {
    background-color: $clr-primary;

    &:hover {
      background-color: darken($clr-primary, 5%);
    }

    span, i {
      color: $color-white !important;
    }
    i:before {
      color: $color-white !important;
    }
  }

  &--secondary {
    background-color: $clr-secondary;

    &:hover {
      background-color: $gray-new;
    }

    span, i {
    }
    i:before {
    }
  }

  &--tertiary {
    background-color: $color-white;

    &:hover {
      background-color: $gray-new;
    }

    span, i {
      color: $clr-primary !important;
    }
    i:before {
    }
  }

  &--danger {
    background-color: $red-primary;

    &:hover {
      background-color: darken($red-primary, 5%);
    }

    span, i {
      color: $color-white !important;
    }
    i:before {
      color: $color-white !important;
    }
  }

  &--orange {
    background-color: $orange-primary;

    &:hover {
      background-color: darken($orange-primary, 5%);
    }

    span, i {
      color: $color-white !important;
    }
    i:before {
      color: $color-white !important;
    }
  }

  &--success {
    background-color: $green-primary;

    &:hover {
      background-color: darken($green-primary, 5%);
    }

    span, i {
      color: $color-white !important;
    }
    i:before {
      color: $color-white !important;
    }
  }

}
