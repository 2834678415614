.p-activity-participants {
  margin-bottom: 5rem;


  &__date {
    margin-bottom: 1rem;

    span {
      font-size: 1.5rem;
    }

  }

  &__info {
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    i {
      margin-right: 0.5rem;
    }

    &__amount {
      display: flex;
      flex: 0.99;
      align-items: center;
      font-size: 1.5rem;
      margin-right: 2rem;
    }

    &__location {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
    }

  }

  &__list {

    &__header {
      margin: 2rem 0 1rem 5rem;
    }

    &__participants {

      &__ul {
        list-style-type: none;
        margin-bottom: 2.5rem;
      }

      .even-row {
        background-color: #F0F0F0;
      }
      .odd-row {
        background-color: #E0E0E0;
      }

      &__participant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;
        border-radius: 1rem;
        margin-bottom: 1rem;

        &__first-row{
          border-radius: 0.7rem;
          margin-bottom: 0.5rem;
          padding: 0.5rem;
        }

        &__name {
          flex: 0.33;
        }

        &__input-names {
          margin-left: 1rem;
          margin-right: 1rem;

          &__title {
            font-size: 1.5rem;
            margin-right: 1.5rem;
          }

          span{
            font-size: 1.5rem;
          }

          input {
            outline: none;
            margin-left: 1rem;
            margin-right: 1rem;
            border: 1px solid #ccc;
            border-radius: 1rem;
            height: 3.5rem;
            padding: 0 1rem;
          }

        }

        &__payment-status{
          display: flex;
          align-items: center;
          width: 100%;
          flex: 0.5;

          &__title{
            font-size: 1.5rem;
          }

          span{
            font-size: 1.5rem;
            margin-right: 1rem;
          }

          button{
            background-color: #ef8009;
            color: #fff;
            font-size: 1.3rem;
            font-weight: bold;
            padding: 0.8rem;

            @media screen and (max-width: 1024px) {
              padding: 3rem 1rem;
            }
          }
        }

      }

    }

  }

  &__sort {
    &__title {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
    &__button{
      margin-top: 0.5rem;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;

    .action-btn {
      color: #fff;
      font-weight: bold;
      background-color: rgb(239, 128, 9);
      padding: 1rem 2rem;
      text-align: center;

      &__back {
        margin-left: 4rem;
      }
    }
  }

}