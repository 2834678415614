.c-toggle-button{
  position: relative;
  display: inline-block;
  width: 10.5rem;
  height: 4rem;

  input{
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 3rem;
    background-color: #ccc;
    -webkit-transition: .2s;
    transition: .2s;
    user-select: none;
    font-size: 1.2rem;
    color: #000;
    text-align: end;
    vertical-align: center;
    padding-right: 1rem;
    padding-top: 1rem;

    &.is-active {
      text-align: start;
      padding-left: 1.5rem;
      color: #fff;
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
  }

  &__slider:before {
    position: absolute;
    content: "";
    height: 3rem;
    width: 3rem;
    left: 1rem;
    bottom: 0.5rem;
    border-radius: 50%;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
  }

  input:checked + &__slider {
    background-color: rgb(239, 128, 9);
  }

  input:focus + &__slider {
    box-shadow: 0 0 2px rgb(239, 128, 9);
  }

  input:checked + &__slider:before {
    -ms-transform: translateX(5.5rem);
    transform: translateX(5.5rem);
  }
}